<template >
  <div>
    <br>
    <b-row>
      <b-col><h4 :style="styleLabelColor" class="text-bold"><b-icon icon="files" font-scale="1.5"/>  Consulta de Documentos</h4></b-col>
      <br><br>
    </b-row>
    <b-row>
      <b-col class="card-shadow">
        <br>
        <b-row>
          <b-col cols="12" sm="12" md="4" lg="4" xl="4">Consultar por:<b-form-select v-model="consultarPor" :options="consultarPorOptions"></b-form-select></b-col>
          <b-col cols="12" sm="6" md="4" lg="4" xl="4" v-if="consultarPor=='fecha'">Fecha inicio:<b-form-datepicker size="sm" v-model="fecCreacionIni"  locale="es" placeholder="Ingrese una fecha"></b-form-datepicker></b-col>
          <b-col cols="12" sm="6" md="4" lg="4" xl="4" v-if="consultarPor=='fecha'">Fecha final:<b-form-datepicker size="sm" v-model="fecCreacionFin"  locale="es" placeholder="Ingrese una fecha" ></b-form-datepicker></b-col>
          <b-col cols="12" sm="6" md="4" lg="4" xl="4" v-if="consultarPor=='estado'">Estado:<b-form-select v-model="estadoDocSelect" :options="estadosOptions" placeholder="Selecciona un estado"></b-form-select></b-col>
          <b-col cols="12"><b-alert :show="dismissCountDown" dismissible variant="danger" @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged">{{msgFormError}}</b-alert></b-col>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top:10px;"><b-button @click="documentosCreados" :variant="principalVariant">Consultar <b-icon icon="search"></b-icon></b-button><br><br></b-col>          
        </b-row>
      </b-col>      
    </b-row>
    <br>
    <b-row>
      <b-col class="card-shadow" >    
        <b-row  align-h="center" align="center">
          <b-col cols="12">
            <br>
            <h6 :style="styleLabelColor" class="text-bold">Resultados<span v-if="docsCreados!=null && docsCreados.length>1"> ({{docsCreados.length}} documento<span v-if="docsCreados.length>1">s</span>)</span></h6>
          </b-col>
          <b-col>
            <b-table responsive stacked="md" striped small sticky-header :items="docsCreados" :fields="fieldsDocsCreados" class="table2class" sort-icon-left>
              <template v-slot:cell(acciones)="row">
                <b-dropdown size="sm" variant="success" text="Acciones" >
                  <b-dropdown-item @click="verDocumento(row.item.idDocumento)">Ver</b-dropdown-item>
                  <b-dropdown-item @click="descargarDocumento(row.item.idDocumento)">Descargar</b-dropdown-item>
                  <b-dropdown-item @click="verModalEliminar(row.item.idDocumento)">Eliminar</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <br>
          </b-col>  
        </b-row>
      </b-col>
    </b-row>
    <br>

    <!----------- MODAL CONFIRMACION ELIMINAR -------------->
    <b-modal  v-model="isShowModalEliminar"  hide-footer  >
      <template #modal-title><h3>¿Desea elimar el documento?</h3></template>
      <b-row align-h="center">
        <b-col cols="7"><b-button size="lg" block variant="success" @click="eliminarDocumento"> Si, Eliminar</b-button><br></b-col>
        <b-col cols="7"><b-button size="lg" block variant="danger" @click="isShowModalEliminar=false">No, Cancelar</b-button></b-col>
      </b-row>
    </b-modal>
    <!----------- MODAL DOCUMENTO -------------->
    <b-modal modal-class="doc-modal" title="Documento" body-bg-variant="light" header-bg-variant="dark" header-text-variant="light" v-model="isShowModalDocAdmin" scrollable hide-footer  >
      <b-row align-h="center">
        <b-col cols="12" sm="12" md="10" lg="8">
          <Documento
            :doc="docPublico"
            :tipoVista="'admin'"
            :participantePublico=null
            :esUsadoEnMisDocumentos="true"
            >
          </Documento>
        </b-col>
      </b-row>
    </b-modal>
    
  </div>
</template>

<script>
  //import { minLength, maxLength, required, email } from 'vuelidate/lib/validators';
  import { mapState} from 'vuex';
  import { obtenerDocumentosCreados, obtenerDocumentoUsuarioParaAdmin, borrarDocumento} from '@/axios-tools';
  import Documento from './Documento.vue'
  export default {
    computed: {
      ...mapState({
        token: state => state.user.token,
        principalVariant: state => state.user.principalVariant,
        secondaryVariant: state => state.user.secondaryVariant,
        styleLabelColor: state => state.user.styleLabelColor,
        baseColor: state => state.user.baseColor,
        docsCreados: state => state.user.docsCreados,
      }),
      estadosOptions: function () {
        return this.$estadosDocumento
      },
      isShowModalDocAdmin:{
        get:function () {
          return this.$store.state.user.isShowModalDocAdmin;
        },
        set:function (val) {
          this.$store.commit('updateIsShowModalDocAdmin', val)
        }
      },
      docPublico: function() {
          return this.$store.state.user.docPublico;
      }
    },
    
    components: {
      Documento
    },
    data() {
      return {
        fieldsDocsCreados:[
          {
            key: 'nombre',
            label: 'Nombre',
            sortable: true,
          },
          {
            key: 'carpeta',
            label: 'Carpeta',
            sortable: true,
          },
          {
            key: 'fecCreado',
            label: 'Creado',
            sortable: true,
          },
          {
            key: 'estado',
            label: 'Estado',
            sortable: true,
          },
          {
            key: 'acciones',
            label: 'Accciones'
          }
        ],
        consultarPor:"fecha",
        consultarPorOptions:[{ value: "fecha", text: 'Fecha de creación' }, { value: "estado", text: 'Estado del documento' }],
        estadoDocSelect:this.$estadoDocVoBo,
        fecCreacionIni:'',
        fecCreacionFin:'',
        isShowModalEliminar:false,
        idDocumentoEliminar:'',
        dismissSecs: 5,
        dismissCountDown: 0,
        msgFormError:''
      }
    },
    methods: { 
      verDocumento(idDocumento){
        obtenerDocumentoUsuarioParaAdmin(this.$store, this.token, idDocumento, true)
      },
      descargarDocumento(idDocumento){
        obtenerDocumentoUsuarioParaAdmin(this.$store, this.token, idDocumento, false)
      },
      eliminarDocumento(){
        borrarDocumento(this.$store, this.token, this.idDocumentoEliminar)
        this.isShowModalEliminar=false
      },
      verModalEliminar(idDocumento){
        this.idDocumentoEliminar=idDocumento
        this.isShowModalEliminar=true
      },
      documentosCreados(){
        if(this.consultarPor=='fecha' && (this.fecCreacionIni=='' || this.fecCreacionFin=='')){
          this.msgFormError="Debes ingresar un periodo de búsqueda"
          this.dismissCountDown = this.dismissSecs //muestra un alert
        }else{
          obtenerDocumentosCreados(this.$store, this.token, this.consultarPor, this.fecCreacionIni, this.fecCreacionFin, this.estadoDocSelect);
        }
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
    },
    validations: {
      
    }
  }
</script>
<style>
	.table2class{
    font-size:small;
    min-height: 120px;
  }
  .doc-modal .modal-dialog {
    max-width: 100% ;
  }
</style>
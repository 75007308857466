<template >
  <div>
    <br>
    <b-row v-if="docsPorFirmar!=null && docsPorFirmar.length==0">
      <b-col><h4 :style="styleLabelColor" class="text-bold"><b-icon icon="files" font-scale="1.5"/> Aún no tienes documentos por firmar <b-button @click="documentosPorFirmar" size="sm" variant="info" pill>Actualizar <b-icon icon="arrow-repeat"/></b-button></h4></b-col>
    </b-row>
    <b-row v-if="docsPorFirmar!=null && docsPorFirmar.length==1">
      <b-col><h4 :style="styleLabelColor" class="text-bold"><b-icon icon="file-earmark-text" font-scale="1.5"/> Tienes {{docsPorFirmar.length}} documento pendiente por firmar <b-button @click="documentosPorFirmar" size="sm" variant="info" pill>Actualizar <b-icon icon="arrow-repeat"/></b-button></h4></b-col>
    </b-row>
    <b-row v-if="docsPorFirmar!=null && docsPorFirmar.length>1">
      <b-col><h4 :style="styleLabelColor" class="text-bold"><b-icon icon="files" font-scale="1.5"/> Tienes {{docsPorFirmar.length}} documentos pendientes por firmar <b-button @click="documentosPorFirmar" size="sm" variant="info" pill>Actualizar <b-icon icon="arrow-repeat"/></b-button></h4></b-col>
    </b-row>
    <b-row v-if="docsPorFirmar!=null">
      <b-col class="card-shadow" >    
        <b-row  align-h="center" align="center">
          <b-col>
            <b-table responsive stacked="md" striped small sticky-header :items="docsPorFirmar" :fields="fieldsDocsPorFirmar" class="table1class" sort-icon-left>
              <template v-slot:cell(acciones)="row">
                <b-button pill variant="success" size="sm" @click="showFirmarDocumento(row.item.idDocumento)">Firmar</b-button>
              </template>
            </b-table>
          </b-col>  
        </b-row>
      </b-col>
    </b-row>
    <br>
    <b-row align="center" v-if="docsPorFirmar!=null && docsPorFirmar.length>1"><b-col><b-button size="md" @click="mensajeSoloCorporativo" pill :variant="principalVariant">Firmar documentos masivamente <b-icon icon="pen"/></b-button></b-col></b-row>

    <!----------- MODAL FIRMA -------------->
    <b-modal modal-class="firma-modal" title="Firma del Documento" header-bg-variant="dark" header-text-variant="light" body-bg-variant="light" v-model="isShowModalFirma" @hidden="documentosPorFirmar" scrollable hide-footer  >
      
      <b-row align-h="center">
        <b-col cols="12" sm="12" md="10" lg="8">
          <proceso-publico esFirmaDesdeMisDocumentos="true"></proceso-publico>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
  //import { minLength, maxLength, required, email } from 'vuelidate/lib/validators';
  import ProcesoPublico from './ProcesoPublico.vue'
  import { mapState} from 'vuex';
  import { documentosPendientesFirma, obtenerDocumentoUsuarioParaFirma} from '@/axios-tools';
  export default {
    computed: {
      ...mapState({
        token: state => state.user.token,
        principalVariant: state => state.user.principalVariant,
        secondaryVariant: state => state.user.secondaryVariant,
        styleLabelColor: state => state.user.styleLabelColor,
        baseColor: state => state.user.baseColor,
        docsPorFirmar: state => state.user.docsPorFirmar,
      }),
      isShowModalFirma:{
        get:function () {
          return this.$store.state.user.isShowModalFirma;
        },
        set:function (val) {
          this.$store.commit('updateIsShowModalFirma', val)
        }
      }
    },
    
    components: {
      ProcesoPublico
    },
    data() {
      return {
        fieldsDocsPorFirmar:[
          {
            key: 'nombre',
            label: 'Nombre',
            sortable: true,
          },
          {
            key: 'carpeta',
            label: 'Carpeta',
            sortable: true,
          },
          {
            key: 'fecCreado',
            label: 'Creado',
            sortable: true,
          },
          {
            key: 'fecExpiracion',
            label: 'Expira',
            sortable: true,
          },
          {
            key: 'creadoPor',
            label: 'Propietario',
            sortable: true,
          },
          {
            key: 'tipoParticipante',
            label: 'Participas como',
            sortable: true,
          },
          {
            key: 'acciones',
            label: 'Accciones'
          }
        ],
      }
    },
    methods: { 
      showFirmarDocumento(idDocumento){
        obtenerDocumentoUsuarioParaFirma(this.$store, this.token, idDocumento)
      },
      documentosPorFirmar(){
        documentosPendientesFirma(this.$store, this.token);
      },
      mensajeSoloCorporativo(){
        this.$store.commit("updateMsgUsuario", {codigo:"1999", mensaje:"Función no disponible", causa:"El firmado másivo de documentos solo esta disponible en el Plan Corporativo."});
        this.$store.commit("updateVerMsgUsuario", true);
      }
    },
    validations: {
      
    }
  }
</script>
<style>
	.table1class{
    font-size:small;
  }
  .firma-modal .modal-dialog {
    max-width: 100% ;
  }
</style>